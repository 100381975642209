// background - #F8FBFD
// promary - #3561EB



.label
  font-weight: 600
  color: #000

.form-group
  display: flex
  flex-direction: column
  justify-content: center
  align-items: flex-end

.button-group
  margin: 20px 0
  max-width: 100px

.ant-table table
  border-spacing: 1px 8px
  
  th.ant-table-cell
    padding-bottom: 0 !important
  
.ant-table-cell.ant-table-selection-column 
    border-spacing: initial


.table-row 
  border-radius: 10px !important
  box-shadow: 0px 0px 0px 1px #E2E2E2

.table-row td:first-child 
  border-top-left-radius: 10px
  border-bottom-left-radius: 10px

.table-row td:last-child 
  border-top-right-radius: 10px
  border-bottom-right-radius: 10px

#nomination-calendar 
  .ant-picker-dropdown 
    position: unset !important
    padding: 5px 0 0 0

  .ant-picker-panel-container
    box-shadow: unset

.contract-list-item
    padding: 9px
    margin: 6px
    width: 100%
    border-radius: 4px

    &:hover
      background: rgba(0, 177, 118, 0.08)

    &.selected-contract
      background: #71a84d
      color: white

.nomination-form
  .table-row 
    background-color: #F8FAFF

  .ant-table-header 
    top: -10px
    background: white
    border-radius: 0 !important

  .ant-table-thead
    .ant-table-cell
      padding-bottom: 0
      background: white

th.ant-table-cell
  background: #f5fffb !important

.sidenav

  .ant-menu-submenu-open
    position: relative
    &::before
      content: "",
      width: 1px
      background: #E2E2E2
      position: absolute
      left: 30px
      height: 65%
      top: 50px

  .ant-menu-sub
    text-align: center

    .ant-menu-item
      padding-left: 16px !important
      width: fit-content
      margin-left: auto
      margin-right: auto
      overflow: inherit
      min-width: 137px
      font-size: 14px
      .ant-menu-title-content
          margin: 0

      span#nav-stats 
        position: absolute
        right: -34px
        border: 1px solid #FFE7D0
        background: rgba(242, 146, 58, 0.08)
        color: #F2923A
        font-size: 12px
        font-weight: 400    
        display: flex
        height: 20px
        justify-content: center
        border-radius: 5px
        padding-inline: 2px




.ant-input-number .ant-input-number-handler-wrap 
  display: none


.ant-form-item-required::before 
    position: absolute !important
    right: 0 !important

.ant-modal-confirm-btns
  text-align: center !important

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn
  visibility: visible !important


.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn
  visibility: visible !important


.ant-picker-panels > *:last-child
  display: none !important


.ant-picker-panel-container, .ant-picker-footer
  width: 280px !important


.ant-picker-footer-extra > div
  flex-wrap: wrap !important 


.ant-picker-cell-inner.non-contract-day
  background: rgb(247 247 247) !important

td.ant-table-cell 
  background: white !important

.ant-table-filter-dropdown 
  background: white !important

.ant-menu-submenu.ant-menu-submenu-selected 
  img.active 
    display: block
  img.inactive  
    display: none

.ant-menu-submenu
  img.active 
    display: none
  img.inactive 
    display: block

.ant-menu-item.ant-menu-item-selected 
  img.active 
    display: block
  img.inactive  
    display: none

.ant-menu-item
  img.active 
    display: none
  img.inactive 
    display: block

.error-bg 
  box-shadow: 0px 0px 0px 1px #FF3B30 !important
  
  td
    background: #f6efea !important

.hide-scroll::-webkit-scrollbar, .ant-table-body::-webkit-scrollbar
  display: none

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll, .ant-table-body 
  -ms-overflow-style: none  /* IE and Edge */
  scrollbar-width: none  /* Firefox */

.ant-table-wrapper.create-nomination-table 
  th 
    background: white !important
  td 
    background: #F8FAFF !important
  tr
    box-shadow: none !important

.create-nomiantion-select 
  .ant-select-selector
    background: rgb(113, 168, 77) !important
    color: white !important
    
    span
     color: white !important
  .ant-select-arrow
    color: white !important
      
 
#calendar-box .ant-picker-header-super-prev-btn,#calendar-box .ant-picker-header-super-next-btn, #calendar-box form
  display: none !important

.ant-picker-cell-range-end .ant-picker-cell-inner, .ant-picker-cell-range-start .ant-picker-cell-inner
  border-radius: 10px !important
  width: 27px !important
  height: 27px !important
  display: flex !important
  justify-content: center !important
  align-items: center !important


.ant-pagination-item
  display: none !important

ul.ant-pagination
  li[aria-disabled="false"] button
    color: rgba(113, 168, 77, 1)


.footer-pagination
    position: absolute
    left: -155px
    bottom: 5px
    right: 0
    margin: auto
    width: fit-content

#filter-select-container
  position: relative
  .ant-select-selection-overflow-item 
    display: none !important

  .ant-select-selection-overflow-item-suffix
    display: inline-flex !important

  #search-button
    position: absolute
    top: 0
    right: 5px
    bottom: 0
    margin: auto
    background: white
    outline: none


.filter-select-popup 
  .ant-select-item-option-state
    svg
      font-size: 11px
      position: absolute
      left: 15px
  .ant-select-item-option-selected
    background: unset !important
  .select-all
    color: #71A84D
    font-size: 10px
    font-weight: 400
    text-decoration-line: underline
    cursor: pointer
    text-align: right
    padding: 0px 6px
  .ant-checkbox-inner
    border-color: #71A84D !important

  .ant-checkbox-checked
    .ant-checkbox-inner
      background: white !important
    ::after
      border-color:  #71A84D !important

.joint-ticket-dropdown.ant-select-dropdown 
  border-radius: 0 !important

.nomination-client.ant-select-item-option-active
    background-color: rgba(0, 177, 118, 0.08) !important
    border-radius: 0 !important

.nomination-client.ant-select-item-option-selected
    background-color: #71A84D !important
    color: white !important
    border-radius: 0 !important

    div
      color: white !important

.nomination-client-rounded.ant-select-item-option-active
    background-color: rgba(0, 177, 118, 0.08) !important

.nomination-client-rounded.ant-select-item-option-selected
    background-color: #71A84D !important
    color: white !important


.ant-picker-dropdown .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before
  border: none


.table-row.mg-expandable-row
  cursor: pointer


  &:not(.mg-expandabled-row) 
    td
      background-color: #FFF3E7 !important

  &.mg-expandabled-row
    transform: scale(1)
    position: relative
    border-bottom-left-radius: 0 !important
    border-bottom-right-radius: 0 !important
    

    &::after 
      content: ""
      position: absolute
      width: 100%
      height: 90px
      border: solid 5px #FFF3E7
      left: 0
      top: 0
      border-top-left-radius: 10px
      border-top-right-radius: 10px


.ant-table-row-level-1.table-row
  box-shadow: unset !important

  td.ant-table-cell
    background-color: #FFF3E7 !important

.child-row
  transform: scale(1)
  border-top-left-radius: 0 !important

  td
    border-radius: 0 !important
    padding: 13px 16px !important
    transform: scale(1)
    z-index: 1

  .child-border-left
    &::before
      content: ""
      position: absolute 
      z-index: 10000 !important
      height: 103%
      background: #e2e2e2 !important
      top: -8px !important
      left: 0px

  .child-border
    &::before
      content: ""
      position: absolute
      z-index: 100
      height: 103%
      width: 1px
      background: #E2E2E2
      top: -7px
      right: -1px
    
  td[rowspan]
    &::after
        content: ""
        position: absolute
        z-index: 100
        height: 8px
        width: 100%
        border-top: solid 1px #E2E2E2
        right: 0
        background: #fff3e7
        top: -8px
    

  td[rowspan]:not(.child-border)
    &::before
        content: ""
        position: absolute
        z-index: 100
        height: 103%
        width: 1px
        background: #fff3e7
        top: -7px
        right: -1px


  td:not([rowspan])
    &::before
      content: ""
      position: absolute
      z-index: 100
      height: 8px
      width: 100%
      border-top: solid 1px #E2E2E2
      right: 0
      background: #fff3e7
      top: -8px
    &::after
      content: ""
      position: absolute
      z-index: 100
      height: 112%
      width: 2px
      border-left: solid 1px #fff3e7
      bottom: -2px
      right: -2px
      top: -7px

    

  


#intraday-form-container 

  .scheduledQty
    .ant-form-item-explain-error
      display: block !important

  .ant-form-item-explain-error
    display: none
  input, .ant-select-selector, .ant-input-number 
    border-color: #d9d9d9 !important

.ant-typography-ellipsis
  &::after
    content: ""
    display: block

.info-card 
  border-radius: 8px
  border: solid 1px #71A84D
  background-color: white
  padding: 12px 12px 12px 12px
  display: flex
  flex-direction: column
  gap: 5px
  h4 
    font-size: 17px


.hide-sr-no
  &::after
    z-index: 10000
    content: ""
    display: block
    position: absolute
    border: solid
    width: 82px
    left: 0
    border-color: #fff3e7
    top: -8px


.parent-row 
  td.admin-marker::after
    content: "",
    position: absolute
    height: 101% !important
    width:11px !important
    background: #71A84D
    top: 0 !important
    left: 0
    z-index: 1
    border-radius: 10px 0 0 10px

.child-row 
  td.admin-marker::after
    content: "",
    position: absolute
    height: 112% !important
    width:11px !important
    background: #71A84D
    top: -7px !important
    left: 0
    z-index: 1000


.mg-expandabled-row.mg-expandable-row
  td.admin-marker::after
    background: none


.ant-table-column-sorters
  display: flex
  justify-content: center !important
  .ant-table-column-title
    flex: none

.ant-table-filter-column
  display: flex
  justify-content: center !important
  .ant-table-column-title
    flex: none


.reset-button-calendar
  width:  120px

  &:not([disabled])
    color: #71a84d
    border-color: #71a84d

.hover-zoom
  transition: transform .2s

  &:hover
    transform: scale(1.05)

  
  

.ant-btn 
  box-shadow: none

.contract-form .ant-picker-input
  display: flex
  flex-direction: row-reverse
  justify-content: space-between
  gap: 10px

.ant-input-disabled, input[disabled] 
  color: #7a7979 !important

.ant-select-disabled
  .ant-select-selection-item
    color: #7a7979 !important

span.ant-upload-list-item-actions 
    display: none

.ant-upload-list-item.ant-upload-list-item-done
  text-align: left

.white-header
  th
    background: white !important


.ant-tag-yellow
  border-color: #fde564 !important

.ant-form-item-required
  &:not(.ant-form-item-required-mark-optional)
  &::before
    font-family: 'Poppins', sans-serif !important

.registration-form
  .ant-btn-primary:disabled
    background-color: #979797 !important
    color: white !important

#registration-form_password_help
  background: white
  z-index: 1


.client-profile-tab
  .ant-tabs-content.ant-tabs-content-top
    overflow: hidden

.registration-summary-div
  padding-inline: 40px
  padding-top: 4px
  .hide-me
    display: none !important
  .show-me
    display: block !important

.ant-upload-list-item-name
  text-overflow: ellipsis
  width: 200px

.mg-input-search
  button
    border-left: none
    span
      color: rgb(113, 168, 77)


  

