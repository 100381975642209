



.top-nav
    width: 100%
    height: 100%
    padding: 0 1.5rem
    background-color: #ffffff
    display: flex
    justify-content: space-between
    align-items: center
    box-sizing: border-box
    z-index: 0

.topnav-profile
    display: flex
    align-items: center





.user-details
    display: flex
    flex-direction: column
    align-items: center
    min-height: 8rem



