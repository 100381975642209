.signin::after
    content: ""
    display: block
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-image: linear-gradient(120deg, #eaee44, #33d0ff)
    opacity: .7

.signin
    background-size: cover
    display: flex
    justify-content: center
    align-items: center
    height: 100%
    width: 100%
    position: absolute

.signin-container
    z-index: 1
    padding: 2rem

.signin-container > div
    background-color: white
    border-radius: 4px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center



.page-container
    padding: 20px
    background-color: #F5FFFB
    border-radius: 36px
    border: 1px solid var(--card-line-separator-color, #E2E2E2)
    width: 96%
    align-self: center
    overflow: initial
    height: 90vh
    overflow-y: scroll

.form-container
    width: 100%

.form-column
    flex: auto
    max-width: 20rem
    width: 100%
    margin-right: 10px 

.form-column-spoc
    display: flex
    align-items: end
    gap: 10px
    width: 100%

.form-column-spoc > *
    flex: auto
    width: 100%
    margin-bottom: 24px

.page-header
    padding: 10px 0
    display: flex
    justify-content: end

@media only screen and (max-width: 830px) 
    .form-container
        flex-direction: column

    .form-column
        width: 100%

@media only screen and (max-width: 1100px)
    .container
        margin-left: 0rem

@media only screen and (max-width: 690px)
    .form-column-spoc
        flex-direction: column